
import request from '@/request';
const part_url = "/admin/user/";
/**
 * 分页列表(前台)
 *
 * @param {*} pageNow 当先页数;默认为1
 * @param {*} pageSize 每页条数;默认为10
 * @param {*} name 用户名
 * @param {*} realName 真实姓名
 * @param {*} email 电子邮箱
 * @param {*} telephone 电话
 * @param {*} status 状态
 * @returns
 */
function pageList(pageNow, pageSize, name, status,tags) {
    return request.get(part_url+'getPage',{
        pageNow,
        pageSize,
        name,
        status,tags
    });
}

/**
 * 禁用启用用户
 *
 * @param {*} ids 用户编号，多个可用逗号隔开
 * @param {*} status 状态
 * @returns
 */
function prohibit(ids, status) {
    return request.post(part_url+'changeStatus',{},{  ids,
        status });

}

/**
 * 根据ID获取详细信息
 *
 * @param {*} id 编号
 * @returns
 */
function getById(id) {
    return request.get(part_url+'getById',{id})
}

/**
 * 删除
 *
 * @param {*} id 编号
 * @returns
 */
function del(id) {
    return request.get(part_url+'delete', { id })
}

function syncToIM(){
    return request.get(part_url+'syncToIM', {})
}


function addOrUpdate(params) {
    return request.post(part_url+'addOrUpdate', {}, params);
}


function resetPassword(userId, password) {
    return request.get(part_url+'resetPassword', { userId, password })
}

export default {
    pageList,
    prohibit,
    getById,
    del,
    addOrUpdate,
    resetPassword,
    syncToIM
}