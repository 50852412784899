import request from '@/request';

const part_url = "/admin/role/";
export default {
    /**
     * 获取所有可用角色
     */
    getRolesAll() {
        let url = part_url + "getAll";
        return request.get(url);
    },
    /**
    * 查询角色详情
    */
    getById(params) {
        let url = part_url + "getById";
        return request.get(url, { id: params });
    },
    /**
    * 获取所有权限
    */
    getAllClaims() {
        let url = part_url + "getAllClaims";
        return request.get(url, { type: "peixun" });
    },
    /**
    * 修改角色状态 ids 角色id用,拼接 status 状态
    */
    updateStatus(params) {
        let url = part_url + "updateStatus";
        return request.get(url, params);
    },
    /**
    * 添加角色 
    */
    addOrUpdate(data) {
        let url = part_url + "addOrUpdate";
        return request.post(url, {  }, data);
    },
   
    /**
     * 查询所有后台用户可用角色
     * @returns 
     */
    getForAdmin() {
        let url = part_url + "getForAdmin";
        return request.get(url, {});
    },
    getDataPurviewKeys(){
        let url = part_url + "getDataPurviewKeys";
        return request.get(url,{});
    }, 

}