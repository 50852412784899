<template>
  <a-spin :tip="loadingTip" :spinning="isloading">
    <div>
      <a-form-model
        ref="userForm"
        :model="info"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-model-item label="用户名" prop="userName">
          <a-input placeholder="请输入" v-model="info.userName"></a-input>
        </a-form-model-item>
        <a-form-model-item label="密码" prop="password">
          <a-input-password
            :placeholder="infos && infos.id>0?'密码不修改可留空':'请输入密码'"
            v-model="info.password"
          ></a-input-password>
        </a-form-model-item>
  
        <a-form-model-item label="姓名">
          <a-input placeholder="请输入" v-model="info.realName"></a-input>
        </a-form-model-item>
        <a-form-model-item label="手机号">
          <a-input placeholder="请输入" v-model="info.telephone"></a-input>
        </a-form-model-item>
        <a-form-model-item label="标签">
           <div>
            <template v-for="(tag, index) in info.tags">
              <a-tooltip v-if="tag.length > 20" :key="tag" :title="tag">
                <a-tag color="#f50" :key="tag" :closable="index !== 0" @close="() => handleClose(tag)">
                  {{ `${tag.slice(0, 20)}...` }}
                </a-tag>
              </a-tooltip>
              <a-tag v-else color="#f50" :key="tag" :closable="true" @close="() => handleClose(tag)">
                {{ tag }}
              </a-tag>
            </template>
            <a-input
              v-if="inputVisible"
              ref="input"
              type="text"
              size="small"
              :style="{ width: '78px' }"
              :value="inputValue"
              @change="handleInputChange"
              @blur="handleInputConfirm"
              @keyup.enter="handleInputConfirm"
            />
            <a-tag v-else style="background: #fff; borderStyle: dashed;" @click="showInput">
              <a-icon type="plus" /> 添加标签
            </a-tag>
          </div>
        </a-form-model-item>
        <a-form-model-item label="所属角色" >
             <a-select v-model="info.roleIds" mode="multiple" placeholder="请选择" @change="roleselected">
              
                <template v-for="tag in roleAll">
                  <a-select-option :value="tag.id" :key="tag.id">
                    {{ tag.name }}
                  </a-select-option>
                </template>
              </a-select>
        </a-form-model-item>
        <a-form-model-item label="个性签名">
          <a-input placeholder="请输入" v-model="info.signature"></a-input>
        </a-form-model-item>
        <a-form-model-item label="手写签字">
          <a-upload
            :action="uploadFileUrl"
            :fileList="fileList"
            list-type="picture-card"
            @change="handleFileChange"
          >
            <div v-if="fileList.length<1">
              <a-icon type="plus" />
              <div class="ant-upload-text">
                上传签字照片
              </div>
            </div>
          </a-upload>
        </a-form-model-item>
        <a-form-model-item label="用户状态">
          <a-switch
            v-model="info.status"
            checkedChildren="启用"
            unCheckedChildren="禁用"
          />
        </a-form-model-item>
        <a-form-model-item :wrapper-col="{ span: 10, offset: 6 }">
          <a-button name="确认" type="primary" @click="handleSubmit">确认</a-button>
          <a-button
            name="取消"
            type="default"
            @click="cancelClick"
            style="margin-left:5px;"
          >取消</a-button>
        </a-form-model-item>
      </a-form-model>
    </div>
  </a-spin>
</template>

<script>
import userApi from '@/api/user/user'
import roleApi from '@/api/system/role'
import request from "@/request";
let _this;
export default {
  props: {
    infos: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    let validatePass = (rule, value, callback) => {
      if (value === "" && this.infos.id == 0) {
        callback(new Error("请输入密码"));
      } else {
        callback();
      }
    };
    return {
      isloading: false,
      loadingTip: "加载中...",
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
      info: {
        password: "",
        status: true,
        group: 0,
        tags:[]
      },
      fileList:[],
      uploadFileUrl:"",
      rules: {
        userName: [{ required: true, message: "请输入用户名", trigger: "blur" }],
        realName: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        password: [{ validator: validatePass, trigger: "change" }],
        telephone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
        ],
        roles: [
          { required: true, message: "请输入选择角色", trigger: "change" },
        ],
      },
      isVal: false,
      roleAll: [],
      inputVisible:false,
      inputValue:''
    };
  },
  mounted() {
    _this = this;
    this.uploadFileUrl = request.getUploadUrl('uas')
    _this.getRoleAll();
    if(this.infos){
      this.info={...this.infos};
      this.info.password=null;
      this.info.status=_this.$util.int2Boolean(this.info.status)
      if(this.infos.roles){
        this.info.roleIds=this.infos.roles.map(r=>r.id);
      }
      if(this.info.signPicture){
        var files=[];
        files.push({
          uid: 1,
          name: '手写签字',
          status: 'done',
          response: {
            url:this.info.signPicture
          }, 
          url: this.info.signPicture,
        })
        this.fileList=files;
      }
    }
  },
  methods: {
    handleFileChange(fileInfo) {
      _this.fileList = fileInfo.fileList;
      if (fileInfo.file.status == 'done') {
        if (fileInfo.file.response.state != 'SUCCESS') {
          _this.$message.error('上传失败');
        } else {
          _this.info.signPicture = fileInfo.file.response.url;
        }
      } else if (fileInfo.file.status == 'error') {
        _this.$message.error('上传失败');
      } else if (fileInfo.file.status == 'removed') {
        _this.info.signPicture = '';
      }
    },
    roleselected(v){
      this.info.roleIds=v;
      _this.$forceUpdate()
    },
    handleClose(removedTag) {
      this.info.tags = this.info.tags.filter(tag => tag !== removedTag);
    },

    showInput() {
      this.inputVisible = true;
      this.$nextTick(function() {
        this.$refs.input.focus();
      });
    },

    handleInputChange(e) {
      this.inputValue = e.target.value;
    },

    handleInputConfirm() {
      const inputValue = this.inputValue;
      let tags = this.info.tags;
      if (inputValue && tags.indexOf(inputValue) === -1) {
        tags = [...tags, inputValue];
      }
      this.info.tags=tags;
      Object.assign(this, {
        inputVisible: false,
        inputValue: '',
      });
    },
    cancelClick() {
      _this.$emit("callbackMethod");
    },
    getRoleAll(){
      roleApi
        .getRolesAll()
        .then((res) => {
          if (res.errorCode == _this.$commons.RespCode.success) {
            _this.roleAll = res.data;
          } else {
            _this.$message.error(res.errorMsg);
          }
         
          _this.isloading = false;
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    handleSubmit() {
      //提交
      _this.$refs.userForm.validate((valid) => {
        if (valid) {
          let formData = { ..._this.info };
          if(formData.roleIds){
            formData.roles=formData.roleIds.map(r=>{
              return {
                id:r
              }
            })
            delete formData.roleIds;
          }        
          if(this.fileList.length>0){
            formData.signPicture=this.fileList[0].response.url
          }
          formData.status = _this.$util.boolean2Int(formData.status);
          _this.isloading = true;
          _this.loadingTip = "保存中...";
          userApi
              .addOrUpdate(formData)
              .then((res) => {
                if (res.errorCode == _this.$commons.RespCode.success) {
                  _this.$message.success(
                    res.errorMsg ? res.errorMsg : "保存成功"
                  );
                  _this.$emit("callbackMethod",true);
                } else {
                  _this.$message.error(res.errorMsg);
                }
                _this.isloading = false;
              })
              .catch(() => {
                _this.isloading = false;
              });
        } else {
          return false;
        }
      });
    },
    filterOption(inputValue, option) {
      return option.title.indexOf(inputValue) > -1;
    },
    handleTransferChange(targetKeys) {
      _this.info.roles = targetKeys;
    },
    
    getUserInfo() {
      _this.isloading = true;
      userApi
        .getById(_this.infos.id)
        .then((res) => {
          if (res.errorCode ==_this.$commons.RespCode.success) {
            res.data.password = "";
            _this.info = res.data;
            _this.info.status = _this.$util.int2Boolean(res.data.status);
            if(res.data.groups){
              _this.info.group=res.data.groups[0].id;
              delete _this.info.goups;
            }
          } else {
            _this.$message.error(res.errorMsg);
          }
          _this.isloading = false;
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
  },
};
</script>